import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { Form } from 'vform';
import { avatarText } from "@core/utils/filter";
import store from '@/store';

import {
    BCard,
    BRow,
    BContainer,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BCardText,
    BInputGroupAppend,
    BFormGroup, BFormSelect,
    BDropdownItem,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BCardBody,
    BPagination,
    BFormTextarea,
} from "bootstrap-vue";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from './pt.js'
import gql from "graphql-tag";
import vSelect from "vue-select";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const DELETE_EVENT = gql`
mutation deleteEvent ($uuid_event : uuid!) {
    delete_events(where: {uuid_event: {_eq: $uuid_event}}) {
      affected_rows
    }
  }  
`;

const DELETE_USERS_EVENT = gql`
mutation deleteUsersEvents ($uuid_event : uuid!) {
    delete_events_users(where: {event_uuid: {_eq: $uuid_event}}) {
      affected_rows
    }
  }
`
    ;

const GET_MY_EVENTS = gql`
query getMyEvents($uuid_user: uuid) {
    events(where: {events_users: {user_uuid: {_eq: $uuid_user }}}) {
      title
      date
      dateevent: date
      start :date
      end: end_date
      enddateevent : end_date
      uuid_event
      allDay
      description
      location {
        uuid_location
        name
        lng
        lat
        city {
            state {
                uf
                name
            }
          name
        }
      }
      events_users {
        user {
          uuid_user
          user_email
          user_first_name
          user_last_name
          user_fullname
          user_role
          user_image
          user_firebase_uid
          status
        }
      }
    }
  }
  `;

const GET_EVENT = gql`
query getEvent ($uuid_event : uuid) {
    events(where: {uuid_event: {_eq: $uuid_event}}) {
      title
      date
      dateevent: date
      enddateevent : end_date
      start :date
      end: end_date
      uuid_event
      description
      allDay
      location {
        uuid_location
        name
        lng
        lat
        city {
            state {
                uf
                name
            }
          name
        }
      }
      events_users {
        user {
          uuid_user
          user_email
          user_first_name
          user_last_name
          user_fullname
          user_role
          user_image
          user_firebase_uid
          status
        }
      }
    }
  }
  
`;
const GET_EVENTS = gql`
query getEvents {
    events {
      title
      date
      dateevent : date
      enddateevent : end_date
      start :date
      end: end_date
      allDay
      uuid_event
      description
      location {
        uuid_location
        name
        lng
        lat
        city {
            state {
                uf
                name
            }
          name
        }
      }
      events_users {
        user {
            uuid_user
            user_email
            user_first_name
            user_last_name
            user_fullname
            user_role
            user_image
            user_firebase_uid
            status
        }
      }
    }
  }
  `;
const GET_USERS = gql`
query getUsers ($user_role : String!){
    user(where: {user_role: {_eq: $user_role}}) {
      uuid_user
      user_email
      user_first_name
      user_last_name
      user_fullname
      user_role
      user_image
      user_firebase_uid
      status
    }
  }
  `;


const GET_LOCATIONS = gql`
query getLocations {
    locations {
      uuid_location
      name
      lat
      lng
      city {
        uuid_city
        name
        state {
            uf
            name
        }
      }
    }
  }   
  `;

const INSERT_EVENT = gql`
mutation insertEvent($title: String!, $allDay : Boolean,  $description: String, $date: timestamp!, $end_date: timestamp!, $location_uuid: uuid!) {
    insert_events(objects: {title: $title, allDay : $allDay,  description: $description, date: $date, end_date: $end_date, location_uuid: $location_uuid}) {
      affected_rows
      returning {
        uuid_event
      }
    }
  }
  
  `;

const UPDATE_EVENT = gql`
  mutation updateEvent($uuid_event : uuid!, $title: String!, $allDay: Boolean, $description: String, $date: timestamp!,$end_date: timestamp!, $location_uuid: uuid!) {
    update_events(_set: {end_date: $end_date, title: $title, allDay : $allDay, description: $description, date: $date, location_uuid: $location_uuid}, where: {uuid_event: {_eq: $uuid_event}}) {
      affected_rows
      returning {
        uuid_event
      }
    }
  }
  `;

const INSERT_EVENT_USERS = gql`
  mutation insertEventUsers($event_uuid: uuid!, $user_uuid : uuid!) {
    insert_events_users(objects: {event_uuid: $event_uuid, user_uuid: $user_uuid}) {
      affected_rows
    }
  }
  `;

const DELETE_EVENTS_USERS = gql`
  mutation deleteEventsUser($uuid_event: uuid!, $uuid_user: uuid!) {
    delete_events_users(where: {_and: {event_uuid: {_eq: $uuid_event}, _and: {user_uuid: {_eq: $uuid_user}}}}) {
      affected_rows
    }
  }
  
  `;
export default {
    components: {
        vSelect,
        flatPickr,
        BContainer,
        BFormTextarea,
        FullCalendar,
        BCardText,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BCardBody,
        BCardCode,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup, BFormSelect,
        BLink,
        BInputGroupAppend,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        BInputGroupPrepend,
    },

    data() {
        return {
            users: null,
            isBroker: false,
            allDay: false,
            locations: null,
            usersEvent: null,
            editEventMode: false,
            oldDate: null,
            oldEndDate: null,
            titleForm: '',
            titleFormView: '',
            date: null,
            end_date: null,
            podemostra: false,
            selectedEvent: null,
            formEvent: new Form({
                uuid_event: null,
                description: null,
                location_uuid: null,
                title: null,
            }),
            optionsDate:
            {
                locale: Portuguese,
                enableTime: true,
                dateFormat: 'd/m/Y H:i',
                minuteIncrement: 1,
                time_24hr: true,
                /*      minDate: 'today' */
            },
            calendarOptions: {
                locale: 'pt-br',
                allDayText: 'Dia inteiro',
                buttonText: {
                    today: 'Hoje',
                    month: 'Mês',
                    week: 'Semana',
                    day: 'Dia',
                    list: 'Lista',
                },
                height: 630,
                dateClick: this.handleDateClick,
                eventClick: this.eventClick,
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
                initialView: 'timeGridWeek',
                headerToolbar: {
                    start: 'sidebarToggle, prev,next, title',
                    end: 'timeGridWeek,timeGridDay,listMonth',
                },
                /* dayGridMonth, */
                events: [],
                eventColor: '#333d69',
                eventTextColor: '#fff',
                eventContent: function (eventInfo) {
                    return { html: eventInfo.event.title }
                }
            }
        }
    },
    created() {
        if(this.loggedUser){
            
            if (this.loggedUser.user_role == 'broker') {
                this.isBroker = true
            }
            this.getRecords();
        }
    },

    methods: {
        createCORSRequest(method, url) {
            var xhr = new XMLHttpRequest();
            if ("withCredentials" in xhr) {
                // XHR for Chrome/Firefox/Opera/Safari.
                xhr.open(method, url, true);
            } else {
                xhr = null;
            }
            return xhr;
        },
        deleteEvent(event_uuid) {
            this.$bvModal
                .msgBoxConfirm('Deseja realmente excluir este Plantão?.', {
                    title: 'ATENÇÃO!',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value == true) {
                        this.$apollo.mutate({
                            mutation: DELETE_USERS_EVENT,
                            variables: {
                                uuid_event: event_uuid
                            }
                        }).then(() => {
                            this.$apollo.mutate({
                                mutation: DELETE_EVENT,
                                variables: {
                                    uuid_event: event_uuid
                                }
                            }).then(() => {
                                this.getRecords();
                                this.$refs['viewEventModal'].hide()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Sucesso',
                                        icon: 'CheckIcon',
                                        text: 'Plantão deletado!',
                                        variant: 'success',
                                    },
                                },
                                    {
                                        position: 'bottom-right',
                                    })
                            }).catch((error) => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Erro',
                                        icon: 'XIcon',
                                        text: 'Erro ao excluir o Plantão! ' + error,
                                        variant: 'danger',
                                    },
                                },
                                    {
                                        position: 'bottom-right',
                                    })
                            });

                        }).catch((error) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Erro',
                                    icon: 'XIcon',
                                    text: 'Erro ao excluir os usuários do Plantão! ' + error,
                                    variant: 'danger',
                                },
                            },
                                {
                                    position: 'bottom-right',
                                })
                        });
                    }
                })

        },
        formatDate(dateValue) {
            if (dateValue == "" || dateValue == null) {
                return null;
            }
            if (typeof dateValue != "string") {
                dateValue = String(dateValue);
            }
            let year = dateValue.substr(6, 4)
            let month = dateValue.substr(3, 2)
            let day = dateValue.substr(0, 2)
            let hours = dateValue.substr(11, 2)
            let minutes = dateValue.substr(13, 3)
            return year + '-' + month + '-' + day + 'T' + hours + minutes + ':00';

        },
        formatReverseDate(dateValue2) {
            if (dateValue2 == "" || dateValue2 == null) {
                return null;
            }
            if (typeof dateValue2 != "string") {
                dateValue2 = String(dateValue2);
            }
            let year2 = dateValue2.substr(0, 4)
            let month2 = dateValue2.substr(5, 2)
            let day2 = dateValue2.substr(8, 2)
            let hours2 = dateValue2.substr(11, 2)
            let minutes2 = dateValue2.substr(13, 3)
            return day2 + '/' + month2 + '/' + year2 + ' ' + hours2 + minutes2;
        },
        editEvent(event) {

            this.editEventMode = true;
            var usersEv = []
            event[0].events_users.forEach(function (user) {
                usersEv.push(user.user)
            })

            this.usersEvent = usersEv
            this.oldEventUsers = usersEv
            this.allDay = event[0].allDay;
            this.date = this.formatReverseDate(event[0].dateevent);
            this.end_date = this.formatReverseDate(event[0].enddateevent);
            this.oldDate = event[0].dateevent;
            this.oldEndDate = event[0].enddateevent
            this.formEvent.uuid_event = event[0].uuid_event
            this.formEvent.description = event[0].description
            this.formEvent.location_uuid = event[0].location
            this.titleForm = 'Editar plantão';
            this.$refs['eventModal'].show()
        },
        refreshEvents() {
            this.$refs.refCalendar.$emit('rerender-events')
        },
        updateEvent() {
            let that = this
            let {
                uuid_event,
                description,
            } = this.$data.formEvent;
            var date = ''
            var end_date = ''
            if (this.oldDate == this.date) {
                date = this.date
            } else {
                date = this.formatDate(this.date)
            }
            if (this.oldEndDate == this.end_date) {
                end_date = this.end_date
            } else {
                end_date = this.formatDate(this.end_date)
            }
            let location_uuid = this.formEvent.location_uuid.uuid_location
            let allDay = this.allDay
            let r1 = that.oldEventUsers
            let r2 = that.usersEvent
            let del = r1.filter(old => !r2.some(user => user.uuid_user === old.uuid_user))
            let add = r2.filter(old => !r1.some(user => user.uuid_user === old.uuid_user))

            let usersVar = ''
            this.usersEvent.forEach(function (user) {
                usersVar = usersVar + '<span style="font-weight:200">- ' + user.user_fullname + '</span><br>'
            })

            let title = '<div> <span style="font-weight:bold">Local:</span><br> ' + '<span style="font-weight:200">- ' + this.formEvent.location_uuid.name + ' </span>' + ' <br>  <span style="font-weight:bold">Corretores:</span> <br>' + usersVar + '</div> '

            this.$apollo.mutate({
                mutation: UPDATE_EVENT,
                variables: {
                    uuid_event,
                    description,
                    location_uuid,
                    end_date,
                    allDay,
                    title,
                    date,
                }
            }).then((r) => {
                let event_uuid = r.data.update_events.returning[0].uuid_event
                add.forEach(function (user) {
                    let user_uuid = user.uuid_user
                    that.$apollo.mutate({
                        mutation: INSERT_EVENT_USERS,
                        variables: {
                            event_uuid,
                            user_uuid,
                        }
                    }).then(() => {
                        // Users add'
                    }).catch(() => {
                        // Erro 
                    });
                });
                del.forEach(function (user) {
                    let user_uuid = user.uuid_user
                    let uuid_event = that.formEvent.uuid_event
                    that.$apollo.mutate({
                        mutation: DELETE_EVENTS_USERS,
                        variables: {
                            uuid_user: user_uuid,
                            uuid_event,
                        }
                    }).then(() => {
                        // Users dell
                    }).catch(() => {
                        // Erro
                    });
                });
                that.getRecords();
                that.$refs['viewEventModal'].hide()
                that.$refs['eventModal'].hide()
                that.selectedEvent = null
                that.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Plantão atualizado!',
                        variant: 'success',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            }).catch((error) => {
                that.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Plantão não atualizado! ' + error,
                        variant: 'danger',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            });

        },

        newEvent() {
            let that = this;
            let { description, } = this.$data.formEvent;
            var date = ''
            var end_date = ''
            if (this.oldDate == this.date) {
                date = this.date
            } else {
                date = this.formatDate(this.date)
            }
            if (this.oldEndDate == this.end_date) {
                end_date = this.end_date
            } else {
                end_date = this.formatDate(this.end_date)
            }
            let usersVar = ''
            this.usersEvent.forEach(function (user) {
                usersVar = usersVar + '<span style="font-weight:200">- ' + user.user_fullname + '</span><br>'
            })

            let title = '<div> <span style="font-weight:bold">Local:</span><br> ' + '<span style="font-weight:200">- ' + this.formEvent.location_uuid.name + ' </span>' + ' <br>  <span style="font-weight:bold">Corretores:</span> <br>' + usersVar + '</div> '
            let allDay = this.allDay
            let location_uuid = this.formEvent.location_uuid.uuid_location
            this.$apollo.mutate({
                mutation: INSERT_EVENT,
                variables: {
                    description,
                    location_uuid,
                    title,
                    end_date,
                    allDay,
                    date,
                }
            }).then((r) => {
                let event_uuid = r.data.insert_events.returning[0].uuid_event
                this.usersEvent.forEach(function (user) {
                    let user_uuid = user.uuid_user
                    that.$apollo.mutate({
                        mutation: INSERT_EVENT_USERS,
                        variables: {
                            event_uuid,
                            user_uuid,
                        }
                    }).then(() => {
                        // User events add
                    }).catch(() => {
                        // Erro 
                    });

                });
                that.getRecords();
                that.$refs['eventModal'].hide()
                that.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Plantão adicionado!',
                        variant: 'success',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            }).catch((error) => {
                that.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Plantão não adicionado! ' + error,
                        variant: 'danger',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            });

        },
        mounted() {

        },
        getEvent(event_uuid) {
            this.$apollo.query({
                query: GET_EVENT,
                variables: {
                    uuid_event: event_uuid
                },
                fetchPolicy: 'no-cache',
            }).then((data) => {
                this.selectedEvent = data['data']['events'];
            });
        },
        getRecords() {
            if (this.isBroker == true) {
                this.$apollo.query({
                    query: GET_MY_EVENTS,
                    variables: {
                        uuid_user: this.loggedUser.uuid_user
                    },
                    fetchPolicy: 'no-cache',
                }).then((data) => {
                    this.calendarOptions.events = data['data']['events'];
                });
            } else {
                this.$apollo.query({
                    query: GET_EVENTS,
                    fetchPolicy: 'no-cache',
                }).then((data) => {
                    this.calendarOptions.events = data['data']['events'];
                });
            }

            this.$apollo.query({
                query: GET_USERS,
                variables: {
                    user_role: 'broker'
                },
                fetchPolicy: 'no-cache',
            }).then((data) => {
								let users = data['data']['user'];
								// remove inactive users
								users = users.filter(user => user.status == 'active');
								this.users = users;
            });

            this.$apollo.query({
                query: GET_LOCATIONS,
                fetchPolicy: 'no-cache',
            }).then((data) => {
                this.locations = data['data']['locations'];
            });
        },
        handleDateClick: function (arg) {
            let date = arg.date.toLocaleString();
            if (this.isBroker == false) {
                this.date = date
                this.end_date = date
                this.editEventMode = false;
                this.usersEvent = null,
                    this.formEvent.reset();
                this.titleForm = 'Adicionar plantão',
                    this.$refs['eventModal'].show()
            }
        },
        eventClick: function (info) {
            this.titleFormView = 'Visualizar Plantão'
            this.getEvent(info.event._def.extendedProps.uuid_event)
            setTimeout(() => {
                this.$refs['viewEventModal'].show()
            }, 100);
        }
    },
    computed: {
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser
            }
            else {
                return null
            }
        },
    },
    setup() {
        return {
            avatarText,
        }
    }
}